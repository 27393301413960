import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';

import 'vue-multiselect/dist/vue-multiselect.css';

import Multiselect from 'vue-multiselect';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCaretDown, faCirclePlay, faGrip, faXmark, faPhoneFlip, faMagnifyingGlass, faAngleRight, faExternalLinkAlt, faBars, faAngleDown, faCaretRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
library.add([faCaretDown, faCirclePlay, faGrip, faXmark, faPhoneFlip, faMagnifyingGlass, faAngleRight, faExternalLinkAlt, faBars, faAngleDown, faCaretRight, faArrowUp]);
library.add(faFacebookF, faYoutube, faLinkedinIn);

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

import Header from '@/components/partial/Header';
import Footer from '@/components/partial/Footer';

import bootstrap from 'bootstrap/dist/js/bootstrap';

const app = createApp(App)
    app.use(store)
    app.use(router)
    app.use(bootstrap)
    app.component('font-awesome-icon', FontAwesomeIcon)
    app.component('Header', Header)
    app.component('Footer', Footer)
    app.component('multiselect', Multiselect)
    app.mount('#app');
