<template>
    <div>
        <Header />
        <div class="header" style="height: 87vh;">
            <div class="overlay"></div>
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source src="./../assets/video/video.mp4" type="video/mp4">
            </video>
            <div class="container h-100">
                <div class="d-flex h-100 align-items-center">
                    <div class="w-70 text-white">
                        <h1 class="header1 raleway capslock fw600">Quality Feeds for a sustainable aquaculture</h1>
                        <p class="font16 w-65">By providing consistent performance high quality feeds and service from the HEART, we help drive the Philippine aquaculture industry to higher levels of productivity and sustainability.</p>
                        <router-link :to="{ name: 'inquiry' }" class="btn btn-outline-default capslock btn-hvr-default">Get In Touch &nbsp; <font-awesome-icon icon="fa-solid fa-angle-right" /></router-link>
                    </div>
                </div>
            </div>
        </div>
        <section id="normal-content" style="padding-top: 60px">
            <main>
                <h1 class="header3 raleway fw600">GROWING BETTER AND FASTER</h1>
                <p class="font14">We produce feed for a complete range of fish and crustaceans consumed by both the local Philippine market and the export market. The consistent quality and performance of Tateh Aquafeeds is reflected on the quality of life enjoyed by our customers.</p>
            </main>
            <div class="container mt-4">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 res-pad-index">
                        <div class="icon-image">
                            <img :src="`${state.root_url}/images/icons/performance.png`" class="img-fluid">
                        </div>
                        <div class="text-center mt-6 res-pad-content-index">
                            <p class="font14">Consistent quality, high performance feed produced in world class, ISO certified manufacturing facilities</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 res-pad-index">
                        <div class="icon-image">
                            <img :src="`${state.root_url}/images/icons/bioavailable-nutrients.png`" class="img-fluid">
                        </div>
                        <div class="text-center mt-6 res-pad-content-index">
                            <p class="font14">Optimally bioavailable ingredients for fast growth, improved FCR, less waste, and a better environment</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 res-pad-index">
                        <div class="icon-image">
                            <img :src="`${state.root_url}/images/icons/probiotics.png`" class="img-fluid">
                        </div>
                        <div class="text-center mt-6 res-pad-content-index">
                            <p class="font14">Sustainable and healthy produce brought about by Probiotics incorporated in the feed</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 res-pad-index">
                        <div class="icon-image">
                            <img :src="`${state.root_url}/images/icons/palatability.png`" class="img-fluid">
                        </div>
                        <div class="text-center mt-6 res-pad-content-index">
                            <p class="font14">Enhanced feed palatability and attractiveness that lead to better weight gains</p>
                        </div>
                    </div>
                </div>
                <div class="row pb-30">
                    <div class="col-lg-12 mt-4 mb-3">
                        <div class="text-center">
                            <router-link :to="{ name: 'products' }" class="btn btn-primary btn-hvr-success icon-forward capslock">products &nbsp; <font-awesome-icon icon="fa-solid fa-angle-right" class="hvr-icon" /></router-link>
                        </div>
                    </div>
                </div>
                <hr class="hr-black">
                <div class="row mt-4 pb-30">
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 align-items-center align-items-index col-order-2 m-top-index" style="display: flex">
                        <div>
                            <h1 class="header3 raleway fw600">QUALITY FROM THE HEART</h1>
                            <p class="font14 mb-5">Quality is at the HEART of what we do. Each one of us participates and takes ownership in delivering the highest standards of quality and excellence in our products and services.</p>
                            <router-link :to="{ name: 'about' }" class="btn btn-primary btn-hvr-success icon-forward capslock">learn more &nbsp; <font-awesome-icon icon="fa-solid fa-angle-right" class="hvr-icon" /></router-link>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col5-right col-order-1">
                        <img :src="`${state.root_url}/images/quality-heart.png`" class="img-80">
                    </div>
                </div>
                <hr class="hr-black">
                <div class="row pb-30">
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 align-items-center align-items-index col-order-2" style="display: flex">
                        <div>
                            <h1 class="header3 raleway fw600">TRANSFORMING<br>THE WORLD OF AQUACULTURE</h1>
                            <p class="font14 mb-5">We strive to grow the Philippine aquaculture industry sustainably and inclusively – so opportunities are shared equitably.</p>
                            <router-link :to="{ name: 'about', hash: '#aboutus-content' }" class="btn btn-primary btn-hvr-success icon-forward capslock">learn more &nbsp; <font-awesome-icon icon="fa-solid fa-angle-right" class="hvr-icon" /></router-link>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col5-right col-order-1">
                        <img :src="`${state.root_url}/images/home-3.png`" class="img-80">
                    </div>
                </div>
                <hr class="hr-black">
                <div class="row pb-30">
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 align-items-center align-items-index col-order-2" style="display: flex">
                        <div>
                            <h1 class="header3 raleway fw600">WITH YOU EVERY STEP OF THE WAY</h1>
                            <p class="font14 mb-5">Tateh personnel are well trained and exposed in technical and practical aquaculture operations and practices. We work hand-in-hand with our customers to minimize risks and ensure a healthy and bountiful harvest.</p>
                            <a href="/service" class="btn btn-primary btn-hvr-success icon-forward capslock">learn more &nbsp; <font-awesome-icon icon="fa-solid fa-angle-right" class="hvr-icon" /></a>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col5-right col-order-1">
                        <img :src="`${state.root_url}/images/home-2.png`" class="img-80">
                    </div>
                </div>
            </div>
        </section>
        <SpeciesView />
        <section id="normal-content">
            <div class="container">
                <div class="row spacer">
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 align-items-index col-order-2 m-top-index mb-5" style="display: flex">
                        <div>
                            <h1 class="header3 raleway fw600">WATCH AND LEARN</h1>
                            <p class="font14 mb-5">Watch the latest updates and happenings on Sustainable Aquaculture Farming and Technology on Mag-Agri Tayo (PTV Channel 4) every Saturday, from 9:30AM to 10:30AM. Or catch replays on our Youtube page.</p>
                            <a href="https://bit.ly/TatehTV" class="btn btn-primary btn-hvr-success icon-forward capslock">youtube &nbsp; <font-awesome-icon icon="fa-solid fa-angle-right" class="hvr-icon" /></a>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col5-right col-order-1">
                        <img :src="`${state.root_url}/images/tatehtv.png`" class="img-80">
                    </div>
                </div>
                <hr class="hr-black">
                <div class="row mt-4 mb-2">
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 align-items-center align-items-index col-order-2 m-top-index mb-5" style="display: flex">
                        <div>
                            <h1 class="header3 raleway fw600">JUMPSTART WITH OUR APP</h1>
                            <p class="font14 mb-5">Using the all new Tateh AquaBiz app available on Android and iOS, AquaBiz is packed with features, information, and tools to help you jumpstart your aquaculture business.</p>
                            <a href="http://play.google.com/store/apps/details?id=com.tateh.aquabiz&hl=en" class="btn btn-primary btn-hvr-success icon-forward capslock">learn more &nbsp; <font-awesome-icon icon="fa-solid fa-angle-right" class="hvr-icon" /></a>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 col5-right col-order-1">
                        <img :src="`${state.root_url}/images/aquabiz-app.png`" class="img-80">
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script>
import { reactive } from 'vue';
import SpeciesView from '@/components/home/SpeciesView';

export default {
    components: {
        SpeciesView
    },
    setup() {
        const state = reactive({
            root_url: process.env.VUE_APP_ENDPOINT
        });

        return {
            state
        }
    }
}
</script>