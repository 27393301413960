import axios from "axios";
import { ref } from "vue";

export default function globalRepo() {

    const countries     = ref([]);
    const provinces     = ref([]);
    const areas         = ref([]);
    const seminars      = ref([]);
    const webinars      = ref([]);
    const pages1        = ref([]);
    const pages2        = ref([]);
    const results       = ref([]);
    const errors        = ref([]);
    const message       = ref('');
    const status        = ref('');
    const pricemonths   = ref([]);
    const pricedays     = ref([]);

    const getAddress = async (page) => {
        let response = await axios.get(`client/global/address`);
        countries.value = response.data.countries
        provinces.value = response.data.provinces;
        areas.value     = response.data.areas;
    }

    const getSitemaps = async (page) => {
        let response = await axios.get(`client/global/sitemap`);
        seminars.value = response.data.seminars
        webinars.value = response.data.webinars;
        pages1.value = response.data.pages1
        pages2.value = response.data.pages2;
    }

    const storeSearch = async (data) => {
        errors.value = '';
        try {
            let response = await axios.post(`client/global/search`, data);
            results.value = response.data.results;
            status.value = response.status;
        } catch (e) {
            if(e.response.status === 422) {
                errors.value = e.response.data.errors;
                status.value = e.response.status;
            }
        }
    }

    const getPriceWathYear = async (year) => {
        let response = await axios.get(`client/global/price-watch-year?year=${year}`);
        pricemonths.value = response.data.months;
    }

    const getPriceWathMonth = async (month) => {
        let response = await axios.get(`client/global/price-watch-month?month=${month}`);
        pricedays.value = response.data.days;
    }

    return {
        countries,
        provinces,
        areas,
        seminars,
        webinars,
        pages1,
        pages2,
        results,
        errors,
        message,
        status,
        getAddress,
        getSitemaps,
        storeSearch,
        getPriceWathYear,
        pricemonths,
        getPriceWathMonth,
        pricedays
    }

}