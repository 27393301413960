<template>
    <div>
        <div class="offcanvas-menu-overlay" :class="{ 'active' : isMenuActive }" @click="openMenu"></div>
        <div class="canvas-open" @click="openMenu">
            <font-awesome-icon icon="fa-solid fa-bars" />
        </div>
        <div class="offcanvas-menu-wrapper" :class="{ 'show-offcanvas-menu-wrapper' : isMenuActive }">
            <div class="canvas-close">
                <i class="icon_close" style="font-size: 24px;"></i>
            </div>
            <div class="search-icon">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="text-green" style="padding: 5px 2px 0px 6px;" /> &nbsp;
                <input type="text" class="search-input-mobile" v-model="params" v-on:keyup.enter="onEnter" />
            </div>
            <nav class="mainmenu mobile-menu" :class="{ 'tateh-nav' : isMenuActive }">
                <ul>
                    <li><router-link :to="{ name: 'home' }">Home</router-link></li>
                    <li :class="{ 'active' : activeLink == 'products' }"><router-link :to="{ name: 'products' }">Products</router-link></li>
                    <li :class="{ 'active' : activeLink == 'product.guide' }"><router-link :to="{ name: 'product.guide' }">Product Guide</router-link></li>
                    <li :class="{ 'active' : activeLink == 'service' }"><router-link :to="{ name: 'service' }">Services</router-link></li>
                    <li :class="{ 'active' : activeLink == 'species' }"><router-link :to="{ name: 'species' }">Species</router-link></li>
                    <li>
                        <a href="#" @click="openLearn" class="d-flex justify-content-between">
                            <div>Watch & Learn</div>
                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                        </a>
                        <ul class="dropdown" v-show="isLearnOpen" style="margin-left: 15px;">
                            <li><a href="https://www.youtube.com/channel/UCEG4QKyeMeOV0_Qi-3RaYxQ" target="_blank">TATEH TV</a></li>
                            <li><router-link :to="{ name: 'tateh.seminar' }">Seminar</router-link></li>
                            <li><router-link :to="{ name: 'tateh.webinar' }">Webinar on Demand</router-link></li>
                            <li><router-link :to="{ name: 'tateh.newsfeed' }">Newsfeed</router-link></li>
                        </ul>
                    </li>
                    <li><router-link :to="{ name: 'about' }">About Us</router-link></li>
                    <li style="padding-top: 20px;"><router-link :to="{ name: 'inquiry', hash: '#branch-location' }" class="no-border">Branch Location</router-link></li>
                    <li><router-link :to="{ name: 'no-data' }" class="no-border">Careers</router-link></li>
                    <li><a href="https://aquabiz.tateh.com/" target="_blank" class="no-border">AquaBiz</a></li>
                </ul>
            </nav>
            <div id="mobile-menu-wrap"></div>
            <div style="padding-top: 30px;">
                <router-link :to="{ name: 'inquiry' }" class="bk-btn">Get in Touch</router-link>
            </div>
            <div class="top-social">
                <a href="https://www.facebook.com/tatehfeeds"><i class="fa fa-facebook"></i></a>
                <a href="https://bit.ly/TatehTV"><i class="fa fa-youtube-play"></i></a>
                <a href="https://www.linkedin.com/company/santeh-feeds-corporation"><i class="fa fa-linkedin"></i></a>
            </div>
            <ul class="top-widget">
                <li>
                    <a href="tel:+639178173474" style="color: #209f4a;"><font-awesome-icon icon="fa-solid fa-phone-flip" class="text-green" /> +63 917 817 FISH (3474)</a>
                </li>
            </ul>
        </div>
        <header class="header-section">
            <div class="top-nav">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                            <ul class="tn-left">
                                <li><font-awesome-icon icon="fa-solid fa-phone-flip" class="text-green" /> +63 917 817 FISH (3474)</li>
                                <li>
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="text-green" /> &nbsp;
                                    <input type="text" class="search-input"  v-model="params" v-on:keyup.enter="onEnter" />
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-7">
                            <div class="tn-right">
                                <div class="top-social">
                                    <router-link :to="{ name: 'inquiry', hash: '#branch-location' }" class="hvr-wobble-horizontal">Branch Location</router-link>
                                    <!-- <router-link :to="{ name: 'price-watch' }" class="hvr-wobble-horizontal">Price Watch</router-link>
                                    <router-link :to="{ name: 'forecast' }" class="hvr-wobble-horizontal">Weather & Tides</router-link> -->
                                    <a href="https://aquabiz.tateh.com/" target="_blank" class="hvr-wobble-horizontal">&nbsp;<img src="./../../assets/img/flag.png" alt="TATEH" /> &nbsp;&nbsp;AquaBiz</a>
                                    <router-link :to="{ name: 'inquiry' }" class="git-link">Get in Touch</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-item">
                <div class="container" style="height: 80px;">
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="logo">
                                <router-link :to="{ name: 'home' }">
                                    <img src="./../../assets/img/logo-big.png" alt="TATEH" class="tateh-logo" />
                                </router-link>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="nav-menu">
                                <nav class="mainmenu">
                                    <ul>
                                        <li :class="{ 'active' : activeLink == 'products' }"><router-link :to="{ name: 'products' }">Products</router-link></li>
                                        <li :class="{ 'active' : activeLink == 'product.guide' }"><router-link :to="{ name: 'product.guide' }">Product Guide</router-link></li>
                                        <li :class="{ 'active' : activeLink == 'service' }"><router-link :to="{ name: 'service' }">Services</router-link></li>
                                        <li :class="{ 'active' : activeLink == 'species' }"><router-link :to="{ name: 'species' }">Species</router-link></li>
                                        <li :class="{ 'active' : activeLink == 'watch' }">
                                            <a href="#">Watch & Learn</a>
                                            <ul class="dropdown">
                                                <li><a href="https://www.youtube.com/channel/UCEG4QKyeMeOV0_Qi-3RaYxQ" target="_blank" style="color: #000 !important;">TATEH TV</a></li>
                                                <li><router-link :to="{ name: 'tateh.seminar' }" style="color: #000 !important;">Seminar</router-link></li>
                                                <li><router-link :to="{ name: 'tateh.webinar' }" style="color: #000 !important;">Webinar on Demand</router-link></li>
                                                <li><router-link :to="{ name: 'tateh.newsfeed' }" style="color: #000 !important;">Newsfeed</router-link></li>
                                            </ul>
                                        </li>
                                        <li :class="{ 'active' : activeLink == 'about' }"><router-link :to="{ name: 'about' }">About Us</router-link></li>
                                        <li>
                                            <div class="git-button">
                                                <router-link :to="{ name: 'inquiry' }" style="padding: 10px 15px; color: #fff !important; font-size: 13px; font-weight: 700;" class="bk-btn hvr-wobble-horizontal">Get in Touch</router-link>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import globalRepo from '@/repositores/global';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
    setup(props) {
        const router = useRouter();
        const route  = useRoute();
        const { storeSearch } = globalRepo();
        const params = ref('');
        const isMenuActive = ref(false);
        const isLearnOpen = ref(false);
        const activeLink = ref('');

        const openMenu = () => {
            isMenuActive.value = !isMenuActive.value;
        }

        const openLearn = () => {
            isLearnOpen.value = !isLearnOpen.value;
        }

        const onEnter = async () => {
            window.location.href = `search?searchname=${params.value}`
        }

        onMounted(() => {
            if(route.name == 'tateh.tv' || route.name == 'tateh.seminar' || route.name == 'tateh.webinar' || route.name == 'tateh.newssfeed') {
                activeLink.value = 'watch';
            } else {
                activeLink.value = route.name;
            }

            if(route.query.searchname) {
                params.value = route.query.searchname;
            }
        });

        return {
            storeSearch,
            params,
            onEnter,
            isMenuActive,
            openMenu,
            openLearn,
            isLearnOpen,
            activeLink
        }
    }
}
</script>

<style>
.text-green {
    color: #209f4a;
}
.tateh-nav {
    display: block !important;
    clear: both;
    color: #fff;
    margin: 0;
    font-size: .875em;
    margin-top: 20px;
}
.tateh-nav li {
    margin: 0;
    text-align: left;
}
.tateh-nav ul li a {
    margin: 10px;
    padding: 0px 0 10px 0;
    font-weight: 500;
    font-size: 14px;
    color: #19191a;
    border-bottom: 1px solid #e5e5e5;
    transition: all 0.3s;
    width: 100%;
    display: block;
}
</style>