<template>
  <div>
    <router-view />
  </div>  
</template>

<script>
import './assets/css/bootstrap.min.css';
import './assets/library/css/fontawesome.min.css';
// import './assets/library/css/owl.carousel.min.css';
// import './assets/library/css/jquery-ui.min.css';
import './assets/library/css/magnific-popup.min.css';
import './assets/library/css/slicknav.min.css';
import './assets/library/css/style.css';
import './assets/css/main.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

export default {
  
}
</script>