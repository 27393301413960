import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Products.vue')
  },
  {
    path: '/product-guide',
    name: 'product.guide',
    component: () => import('@/views/ProductGuide.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/Service.vue')
  },
  {
    path: '/species',
    name: 'species',
    component: () => import('@/views/Species.vue')
  },
  {
    path: '/about-us',
    name: 'about',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/get-in-touch',
    name: 'inquiry',
    component: () => import('@/views/Inquiry.vue')
  },
  {
    path: '/tateh-tv',
    name: 'tateh.tv',
    component: () => import('@/views/learn/TatehTv.vue')
  },
  {
    path: '/seminar',
    name: 'tateh.seminar',
    component: () => import('@/views/learn/Seminar.vue')
  },
  {
    path: '/webinar',
    name: 'tateh.webinar',
    component: () => import('@/views/learn/Webinar.vue')
  },
  {
    path: '/newsfeed',
    name: 'tateh.newsfeed',
    component: () => import('@/views/learn/Newsfeed.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/History.vue')
  },
  {
    path: '/thank-you',
    name: 'thankyou',
    component: () => import('@/views/Thankyou.vue')
  },
  {
    path: '/brand-and-guidelines',
    name: 'brand',
    component: () => import('@/views/BrandGuideline.vue')
  },
  {
    path: '/privacy-policy',
    name: 'policy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import('@/views/Disclaimer.vue')
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: () => import('@/views/Sitemap.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Search.vue')
  },
  {
    path: '/no-data',
    name: 'no-data',
    component: () => import('@/views/NoData.vue')
  },
  {
    path: '/price-watch',
    name: 'price-watch',
    component: () => import('@/views/Pricewatch.vue')
  },
  {
    path: '/forecast',
    name: 'forecast',
    component: () => import('@/views/Forecast.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
