<template>
  	<div>
		<section id="blue-content">
			<main>
				<h1 class="header3 raleway fw600 text-white">SPECIES CULTURED</h1>
				<p class="font14 text-white">As a leading aquaculture company, we produce feed for all major aquaculture species grown in the Philippines. Our expertise and experience in culturing these species span over decades. Select a species below to know more about them.</p>
			</main>
			<div class="container">
				<div class="row mt-4 mb-2">
					<div class="col-lg-12">
						<carousel :settings="settings" :breakpoints="breakpoints">
							<slide v-for="slide in slides" :key="slide.id">
								<div style="text-align: center; border: 2px solid #eee; padding: 30px 25px; background-color: #f5f5f5;">
									<a :href="slide.link">
                    <img :src="slide.src" alt="TATEH">
									  <h1 class="header4 open-sans fw600">{{ slide.name }}</h1>
                  </a>
								</div>
							</slide>

							<template #addons>
								<div class="tateh-navigate">
									<navigation />
								</div>
								<div class="relative mt-5">
									<pagination />
								</div>
							</template>
						</carousel>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { reactive, ref } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  setup() {
    const state = reactive({
      root_url: process.env.VUE_APP_ENDPOINT
    });

    const slides = ref([
      {
        id: 1,
        name: 'Tilapia',
        src: `${state.root_url}/images/fish/tilapia.png`,
        link: '/species#tilapia'
      },
      {
        id: 2,
        name: 'Milkfish',
        src: `${state.root_url}/images/fish/milkfish.png`,
        link: '/species#milkfish'
      },
      {
        id: 3,
        name: 'P. Vannmei',
        src: `${state.root_url}/images/fish/vannamei.png`,
        link: '/species#vannamei'
      },
      {
        id: 4,
        name: 'P. Monodon',
        src: `${state.root_url}/images/fish/monodon.png`,
        link: '/species#monodon'
      },
      {
        id: 5,
        name: 'Crab',
        src: `${state.root_url}/images/fish/crab.png`,
        link: '/species#crab'
      },
      {
        id: 6,
        name: 'Catfish',
        src: `${state.root_url}/images/fish/catfish.png`,
        link: '/species#catfish'
      },
      {
        id: 7,
        name: 'Pangasius',
        src: `${state.root_url}/images/fish/pangasius.png`,
        link: '/species#pangasius'
      },
      {
        id: 8,
        name: 'Rabbitfish',
        src: `${state.root_url}/images/fish/rabbitfish.png`,
        link: '/species#rabbitfish'
      },
      {
        id: 9,
        name: 'Pompano',
        src: `${state.root_url}/images/fish/pompano.png`,
        link: '/species#pompano'
      },
      {
        id: 10,
        name: 'Grouper',
        src: `${state.root_url}/images/fish/grouper.png`,
        link: '/species#grouper'
      },
      {
        id: 11,
        name: 'Seabass',
        src: `${state.root_url}/images/fish/seabass.png`,
        link: '/species#seabass'
      },
      {
        id: 12,
        name: 'Snapper',
        src: `${state.root_url}/images/fish/snapper.png`,
        link: '/species#snapper'
      },
      {
        id: 13,
        name: 'Eel',
        src: `${state.root_url}/images/fish/eel.png`,
        link: '/species#eel'
      }
    ]);

    const settings = ref({
      itemsToShow: 4,
      snapAlign: 'center-even',
      wrapAround: true
    });

    const breakpoints = ref({
      320: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      1024: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      1200: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    });

    return {
      state,
      slides,
      settings,
      breakpoints
    }
  },
}
</script>

<style>
.carousel__pagination-button::after {
	border-radius: 30px;
	width: 10px;
	height: 10px;
	background-color: rgb(212, 212, 212);
}
.carousel__pagination-button:hover::after, .carousel__pagination-button--active::after {
  	background-color: rgb(255, 255, 255);
}
.carousel__prev:hover, .carousel__next:hover {
  	color: rgb(212, 212, 212);
}
.carousel__prev, .carousel__next {
  	color: rgb(255, 255, 255);
}
.tateh-navigate {
  position: absolute;
  display: inline-flex;
  bottom: 40px;
  width: 80px;
  left: 46.5%;
}
</style>