<template>
    <div>
        <footer class="footer-section">
            <div class="container">
                <div class="footer-text">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-12" style="padding-bottom: 25px">
                            <div class="logo">
                                <a href="#">
                                    <img src="./../../assets/img/footer-logo-big.png" alt="TATEH" class="santeh-logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row footer-768">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="ft-contact">
                                <h6>Main Office</h6>
                                <p class="font13 text-white">
                                    Santeh Feeds Corporation<br>
                                    7th floor, RIchwell Center<br>
                                    102 Timog Avenue<br>
                                    Brgy. Sacred Heart<br>
                                    Quezon City 1103, Philippines
                                </p>
                                <br>
                                <h6>Contact Numbers</h6>
                                <p class="font13 text-white">
                                    +63 2 8375 1560 to 62<br>
                                    +63 2 8374 8031<br>
                                    +63 917 817 FISH (3474)
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="ft-newslatter">
                                <h6>Species</h6>
                                <p class="font13 lh22">
                                    <a href="/species#tilapia" class="text-white" style="text-decoration: none">Tilapia</a><br>
                                    <a href="/species#milkfish" class="text-white" style="text-decoration: none">Milkfish</a><br>
                                    <a href="/species#vannamei" class="text-white" style="text-decoration: none">P. Vannamei</a><br>
                                    <a href="/species#monodon" class="text-white" style="text-decoration: none">P. Monodon</a><br>
                                    <a href="/species#crab" class="text-white" style="text-decoration: none">Crab</a><br>
                                    <a href="/species#catfish" class="text-white" style="text-decoration: none">Catfish</a><br>
                                    <a href="/species#pangasius" class="text-white" style="text-decoration: none">Pangasius</a><br>
                                    <a href="/species#rabbitfish" class="text-white" style="text-decoration: none">Rabbitfish</a><br>
                                    <a href="/species#pompano" class="text-white" style="text-decoration: none">Pompano</a><br>
                                    <a href="/species#grouper" class="text-white" style="text-decoration: none">Grouper</a><br>
                                    <a href="/species#seabass" class="text-white" style="text-decoration: none">Seabass</a><br>
                                    <a href="/species#snapper" class="text-white" style="text-decoration: none">Snapper</a><br>
                                    <a href="/species#eel" class="text-white" style="text-decoration: none">Eel</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="ft-contact">
                                <h6>Product Guide</h6>
                                <p class="font13 lh22" style="padding-bottom: 15px">
                                    <a href="/product-guide#super-premium" class="text-white" style="text-decoration: none">Finfish Diet</a><br>
                                    <a href="/product-guide#early-nutrition" class="text-white" style="text-decoration: none">Early Fish Nutrition Diet</a><br>
                                    <a href="/product-guide#hatchery-feed" class="text-white" style="text-decoration: none">Hatchery Diet</a><br>
                                    <a href="/product-guide#crustacean-diet" class="text-white" style="text-decoration: none">Crustacean Diet</a><br>
                                    <a href="/product-guide#catfish-eel" class="text-white" style="text-decoration: none">Catfish and Eel Diet</a><br>
                                    <a href="/product-guide#marine-fish" class="text-white" style="text-decoration: none">Marine Fish Diet</a>
                                </p>
                                <h6>Watch & Learn</h6>
                                <p class="font13 lh22">
                                    <a href="/tateh-tv" class="text-white" style="text-decoration: none">TatehTV</a><br>
                                    <a href="/seminar" class="text-white" style="text-decoration: none">Seminars</a><br>
                                    <a href="/webinar" class="text-white" style="text-decoration: none">Webinar on Demand</a><br>
                                    <a href="/newsfeed" class="text-white" style="text-decoration: none">Newsfeed</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="ft-newslatter">
                                <h6>Company</h6>
                                <p class="font13 lh22">
                                    <a href="/about-us" class="text-white" style="text-decoration: none">About Us</a><br>
                                    <a href="/history" class="text-white" style="text-decoration: none">History</a><br>
                                    <router-link :to="{ name: 'no-data' }" class="text-white" style="text-decoration: none">Awards and Certifications</router-link><br>
                                    <a href="/brand-and-guidelines" class="text-white" style="text-decoration: none">Brand Guidelines</a><br>
                                    <router-link :to="{ name: 'no-data' }" class="text-white" style="text-decoration: none">Press</router-link><br>
                                    <br>
                                    <a href="/get-in-touch#branch-location" class="text-white" style="text-decoration: none">Branch Location</a><br>
                                    <router-link :to="{ name: 'no-data' }" class="text-white" style="text-decoration: none">Careers</router-link><br>
                                    <a href="https://aquabiz.tateh.com/" target="_blank" class="text-white" style="text-decoration: none">AquaBiz</a><br>
                                    <div style="margin-top: 15px;">
                                        <a href="/get-in-touch#how-can-we-help-you" class="btn btn-primary btn-hvr-success icon-forward capslock" style="border: 1px solid #fff !important">INQUIRE</a>
                                    </div>
                                </p>
                                <div class="fa-social">
                                    <a href="https://www.facebook.com/tatehfeeds"><font-awesome-icon icon="fa-brands fa-facebook-f" class="text-white" /></a>
                                    <a href="https://bit.ly/TatehTV"><font-awesome-icon icon="fa-brands fa-youtube" class="text-white" /></a>
                                    <a href="https://www.linkedin.com/company/santeh-feeds-corporation"><font-awesome-icon icon="fa-brands fa-linkedin-in" class="text-white" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row footer-320">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="ft-contact">
                                <h6>Main Office</h6>
                                <p class="font13 text-white">
                                    Santeh Feeds Corporation<br>
                                    7th floor, RIchwell Center<br>
                                    102 Timog Avenue<br>
                                    Brgy. Sacred Heart<br>
                                    Quezon City 1103, Philippines
                                </p>
                                <br>
                                <h6>Contact Numbers</h6>
                                <p class="font13 text-white">
                                    +63 2 8375 1560 to 62<br>
                                    +63 2 8374 8031<br>
                                    +63 917 817 FISH (3474)
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <ul class="menu-footer">
                                <li><a href="javascript:;" @click="mobileMenu('product')" class="d-flex justify-content-between"><div>PRODUCT GUIDE</div> <font-awesome-icon icon="fa-solid fa-angle-down" /></a>
                                    <ul class="footer-dropdown" :class="{ 'show' : isProductShow }">
                                        <li><a href="/product-guide#super-premium">Finfish Diet</a></li>
                                        <li><a href="/product-guide#early-nutrition">Early Fish Nutrition Diet</a></li>
                                        <li><a href="/product-guide#hatchery-feed">Hatchery Diet</a></li>
                                        <li><a href="/product-guide#crustacean-diet">Crustacean Diet</a></li>
                                        <li><a href="/product-guide#catfish-eel">Catfish and Eel Diet</a></li>
                                        <li><a href="/product-guide#marine-fish">Marine Fish Diet</a></li>
                                    </ul>
                                </li>
                                <li><a href="javascript:;" @click="mobileMenu('species')" class="d-flex justify-content-between"><div>SPECIES</div> <font-awesome-icon icon="fa-solid fa-angle-down" /></a>
                                    <ul class="footer-dropdown" :class="{ 'show' : isSpeciesShow }">
                                        <li><a href="/species#tilapia">Tilapia</a></li>
                                        <li><a href="/species#milkfish">Milkfish</a></li>
                                        <li><a href="/species#vannamei">P. Vannamei</a></li>
                                        <li><a href="/species#monodon">P. Monodon</a></li>
                                        <li><a href="/species#crab">Crab</a></li>
                                        <li><a href="/species#catfish">Catfish</a></li>
                                        <li><a href="/species#pangasius">Pangasius</a></li>
                                        <li><a href="/species#rabbitfish">Rabbitfish</a></li>
                                        <li><a href="/species#pompano">Pompano</a></li>
                                        <li><a href="/species#grouper">Grouper</a></li>
                                        <li><a href="/species#seabass">Seabass</a></li>
                                        <li><a href="/species#snapper">Snapper</a></li>
                                        <li><a href="/species#eel">Eel</a></li>
                                    </ul>
                                </li>
                                <li><a href="javascript:;" @click="mobileMenu('learn')" class="d-flex justify-content-between"><div>WATCH & LEARN</div> <font-awesome-icon icon="fa-solid fa-angle-down" /></a>
                                    <ul class="footer-dropdown" :class="{ 'show' : isLearnShow }">
                                        <li><a href="/tateh-tv">TatehTV</a></li>
                                        <li><a href="/seminar">Seminars</a></li>
                                        <li><a href="/webinar">Webinar on Demand</a></li>
                                        <li><a href="/newsfeed">Newsfeed</a></li>
                                    </ul>
                                </li>
                                <li><a href="javascript:;" @click="mobileMenu('company')" class="d-flex justify-content-between"><div>COMPANY</div> <font-awesome-icon icon="fa-solid fa-angle-down" /></a>
                                    <ul class="footer-dropdown" :class="{ 'show' : isCompanyShow }">
                                        <li><a href="/about-us">About Us</a></li>
                                        <li><a href="/history">History</a></li>
                                        <li><router-link :to="{ name: 'no-data' }">Awards and Certifications</router-link></li>
                                        <li><a href="/brand-and-guidelines">Brand Guidelines</a></li>
                                        <li><router-link :to="{ name: 'no-data' }">Press</router-link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="ft-newslatter">
                                <p class="font13 lh22" style="line-height: 27px;">
                                    <br>
                                    <a href="/get-in-touch#branch-location" class="text-white" style="text-decoration: none">Branch Location</a><br>
                                    <router-link :to="{ name: 'no-data' }" class="text-white" style="text-decoration: none">Careers</router-link><br>
                                    <a href="https://aquabiz.tateh.com/" target="_blank" class="text-white" style="text-decoration: none">AquaBiz</a><br>
                                    <div class="text-center">
                                        <a href="/get-in-touch#how-can-we-help-you" class="btn btn-primary btn-hvr-success icon-forward capslock" style="border: 1px solid #fff !important">INQUIRE</a>
                                    </div>
                                </p>
                                <div class="fa-social text-center">
                                    <a href="https://www.facebook.com/tatehfeeds"><i class="fa fa-facebook"></i></a>
                                    <a href="https://bit.ly/TatehTV"><i class="fa fa-youtube-play"></i></a>
                                    <a href="https://www.linkedin.com/company/santeh-feeds-corporation"><i class="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 bc-footer">
                            <ul>
                                <li><a href="/privacy-policy" class="text-white">Privacy Statement</a></li>
                                <li><a href="/disclaimer" class="text-white">Disclaimer</a></li>
                                <li><a href="/sitemap" class="text-white">Site Map</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-option">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 bl-footer">
                            <ul>
                                <li><a href="/privacy-policy" class="text-white">Privacy Statement</a></li>
                                <li><a href="/disclaimer" class="text-white">Disclaimer</a></li>
                                <li><a href="/sitemap" class="text-white">Site Map</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-5 col-md-12 text-white" style="text-align: center;">
                            &copy; Santeh Feeds Corporation. All Rights Reserved.
                            <span style="display: none">Powered by: CJSeven Inc.</span>
                        </div>
                    </div>
                </div>
            </div>
            <button id="myBtn" title="Go to top"><font-awesome-icon icon="fa-solid fa-arrow-up" /></button>
        </footer>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import $ from 'jquery';

export default {
    setup(props) {
        const isProductShow = ref(false);
        const isSpeciesShow = ref(false);
        const isLearnShow = ref(false);
        const isCompanyShow = ref(false);

        const mobileMenu = (type) => {
            if(type == 'product') {
                isProductShow.value = !isProductShow.value;
            }
            if(type == 'species') {
                isSpeciesShow.value = !isSpeciesShow.value;
            }
            if(type == 'learn') {
                isLearnShow.value = !isLearnShow.value;
            }
            if(type == 'company') {
                isCompanyShow.value = !isCompanyShow.value;
            }
        }

        const initJquery = () => {
            window.$ = window.jQuery = require('jquery');
            $.noConflict();

            var mybutton = document.getElementById("myBtn");

            $('#body').scroll(function () {
                if ($('#body').scrollTop() > 20) {
                    mybutton.style.display = "block";
                } else {
                    mybutton.style.display = "none";
                }
            });

            // When the user clicks on the button, scroll to the top of the document
            $('#myBtn').on("click",function() {
                $('html, body').animate({ scrollTop: 0 }, 'slow');
            });

            $('.menu-footer li a').click(function () {
                $(this).siblings('ul').slideToggle();
            });
        }

        onMounted(() => {
            initJquery(); 
        });

        return {
            mobileMenu,
            isProductShow,
            isSpeciesShow,
            isLearnShow,
            isCompanyShow,
            initJquery
        }
    }
}
</script>

<style scoped>
.show {
    display: block !important;
}
</style>